










































































// @ is an alias to /src
import { Component, Vue, Provide } from "vue-property-decorator";
import LayoutHeader from "../layout/LayoutHeader.vue";
import LayoutFooter from "../layout/LayoutFooter.vue";
import LeftCat from "../layout/LeftCat.vue";
import KeFu from "../layout/KeFu.vue";
import { State, Getter, Mutation, Action } from "vuex-class";

@Component({
  components: { LayoutHeader,LayoutFooter,LeftCat,KeFu },
})
export default class goodsList extends Vue {
  // 公告
  @Provide() run_notice: String = "";
  @Provide() index_notices: String = '';
  // 配置信息
  // @Provide() siteConfig: any = [];
  @Getter('config') siteConfig: any;
  @Provide() goods_list: any = [];
  @Provide() leftCatVisible: boolean = false;
  @Provide() cat_id: String = '';
  @Provide() defaultImg: String = 'this.src="@/assets/all_icon.png"';
  
  created() {
    let utils = (this as any).$utils;
    this.cat_id = utils.praseStrEmpty(this.$route.query.cat_id);
    let kw = utils.praseStrEmpty(this.$route.query.kw);
    utils.navMobile(3,this.cat_id);
    // this.getSystemConfig();
    this.getGoodsList(kw);
  }
  mounted(){
    window.addEventListener('scroll',this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  }
  handleScroll(){
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
    if(scrollTop > 200) {
      this.leftCatVisible = true;
    } else {
      this.leftCatVisible = false;
    }
    
  }
  defImg(event:any){
	  let img = event.srcElement;
	  img.src = require("@/assets/all_icon.png");
	  img.onerror = null; //防止闪图
 }
  loadGoodsList(kw:String) {
    this.getGoodsList(kw);
  }
  // 获取配置信息
  getSystemConfig() {
    console.log('index_config');
    let forms = {
        m: "base.getSystemConfig",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.siteConfig = res.data.data.config;
          document.title = this.siteConfig.webtitle;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 获取商品
  getGoodsList(kw: String) {
    let cat_id = this.cat_id;
    if(kw != '') {
      cat_id = '';
    }
    let forms = {
        m: "goods.getGoodsList",
        kw: kw,
        cat_id: cat_id
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.goods_list = res.data.data.goods_list;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 跳转到商品详细页面
  toGoods(goods_id:any): void {
    // let p:o = ;
    // this.$router.push({ name: 'goods', params: { id: goods_id }});
    this.$router.push({ name: 'goods', query: { id: goods_id }});
  }
}
